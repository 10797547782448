<!-- @format -->

<template>
	<div>
		<div>Get Invoice Data</div>
		<b-button class="is-danger" @click="closeX">Close</b-button>
		<div v-if="loading">
			<div>Loading.....</div>
			<div>Getting Invoice Report please wait....</div>
		</div>
	</div>
</template>
<script>
import { useQuery, useResult } from '@vue/apollo-composable'
import { onMounted, reactive } from '@vue/composition-api'
import Store from '@/store'
import GetInvoiceReportDataQuery from './GetInvoiceReportDataQuery.graphql'
export default {
	props: {
		invoiceNumber: {
			type: String,
			required: true,
		},
		ourCompany: {
			type: String,
			required: true,
		},
		closeGetData: {
			type: Function,
			required: true,
		},
	},
	setup(props) {
		// get invoice report info
		const options = reactive({
			fetchPolicy: 'no-cache',
		})
		const { result, refetch, loading } = useQuery(
			GetInvoiceReportDataQuery,
			() => ({
				our_company: props.ourCompany,
				invoice_number: props.invoiceNumber,
			}),
			options,
		)
		// ** --------------------------------------------------------------------------
		const getReportData = (data) => {
			return new Promise((resolve, reject) => {
				if (data !== null || undefined) {
					const allReportDataForInvoice = useResult(
						result,
						null,
						(data) => data.document_transactions,
					)
					console.log('allReportDataForInvoice', allReportDataForInvoice)
					resolve(allReportDataForInvoice.value[0])
				} else {
					const reason = new Error('Data could not be fetched from database')
					reject(reason)
				}
			})
		}
		// ** --------------------------------------------------------------------
		const storeReportData = (data) => {
			return new Promise((resolve, reject) => {
				if (data !== null || undefined) {
					// console.log('-*-*-* storeReportData', data)
					Store.dispatch('setPdfReportData', data).then(() => {
						resolve(true)
					})
				} else {
					const reason = new Error('Data can not be stored')
					reject(reason)
				}
			})
		}
		// ----------------------------------------------------------------------------
		const createReport = () => {
			getReportData(result).then((data) => {
				storeReportData(data)
			})
		}
		onMounted(() => {
			console.log('GetOrderReport onMounted is fired')
			refetch()
				.then(() => {
					createReport()
				})
				.then(() => {
					props.closeGetData()
				})
		})
		const closeX = () => {
			props.closeGetData()
		}
		return {
			loading,
			closeX,
		}
	},
}
</script>
<style lang="scss"></style>
