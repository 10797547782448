<!-- @format -->

<template>
	<div>
		<div>ProcessInvoice</div>
	</div>
</template>
<script>
import useInvoiceDocDefinition from '@/_srcv2/views/pages/accounting/components/collect-payment/invoiceDocDefinition.js'
import { onMounted } from '@vue/composition-api'
export default {
	name: 'ProcessInvoice',
	props: {
		closeProcessInvoice: {
			type: Function,
			required: true,
		},
		invoiceNumber: {
			type: String,
			required: true,
		},
	},
	setup(props) {
		const { getInvoiceReportData } = useInvoiceDocDefinition()
		// const documentDefinition = getInvoiceReportData()
		// ** --------------------------------------------------------------
		const getData = () => {
			return new Promise((resolve, reject) => {
				const dd = getInvoiceReportData()
				if (dd !== undefined || null) {
					resolve(dd)
				} else {
					const errorObject = {
						msg: "Data couldn't be fetched from store",
					}
					reject(errorObject)
				}
			})
		}
		// ** --------------------------------------------------------------
		const createReport = (dd) => {
			return new Promise((resolve, reject) => {
				if (dd !== undefined || null) {
					const pdfMake = require('pdfmake/build/pdfmake.js')
					const pdfFonts = require('pdfmake/build/vfs_fonts.js')
					pdfMake.vfs = pdfFonts.pdfMake.vfs
					const pdf = pdfMake.createPdf(dd)
					resolve(pdf)
				} else {
					const errorObject = {
						msg: "The report couldn't be created",
					}
					reject(errorObject)
				}
			})
		}
		// ** --------------------------------------------------------------
		onMounted(() => {
			getData()
				.then((data) => createReport(data))
				.then((pdf) => pdf.open())
				.then(() => props.closeProcessInvoice())
		})
	},
}
</script>
<style lang="scss"></style>
