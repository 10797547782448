<!-- @format -->

<template>
	<layout-wide>
		<sub-nav-bar page="Transaction" />
		<GetInvoiceData
			v-if="!!isGetInvoiceDataOpen"
			:invoiceNumber="invoiceNumber"
			:ourCompany="ourCompany"
			:closeGetData="closeGetData"
		/>
		<ProcessInvoice
			v-if="!!isProcessInvoiceOpen"
			:invoiceNumber="invoiceNumber"
			:closeProcessInvoice="closeProcessInvoice"
		/>
		<div>
			<div @click="collapseTable">
				<b-field class="margin-top-20" label="Select payments date">
					<b-datepicker
						v-model="selectedDate"
						:max-date="today"
						:show-week-number="showWeekNumber"
						:locale="locale"
						:first-day-of-week="firstDayOfWeek"
						placeholder="Click to select..."
						icon="calendar-today"
						trap-focus
					>
					</b-datepicker>
				</b-field>
			</div>
			<div>
				<b-button
					class="is-info margin-top-20 margin-right-10"
					type="submit"
					@click="getAll"
					>Get All</b-button
				>
				<b-button
					class="is-danger margin-top-20 margin-right-10"
					type="submit"
					@click="getBank"
					>Get Bank</b-button
				>
				<b-button
					class="is-success margin-top-20 margin-right-10"
					type="submit"
					@click="getCash"
					>Get Cash</b-button
				>
				<b-button
					class="is-warning margin-top-20"
					type="submit"
					@click="getSetoff"
					>Get Set Off</b-button
				>
			</div>
			<div
				class="margin-top-20"
				style="font-weight: 700; font-size: x-large; color: red"
			>
				<p v-if="loading">Loading............</p>
			</div>

			<div v-if="!isProcessing">
				<div
					class="margin-top-20"
					style="font-weight: 700; font-size: x-large; color: red"
				>
					<p v-if="isLengthOfResultZero">Nothing to show</p>
				</div>
				<div v-if="!isLengthOfResultZero">
					<div>
						<div v-if="!loading">
							<div class="has-text-left" style="margin-top: 20px">
								<span class="payment-type"> Total Payment Amount: </span>
								{{ totalPaymentAmount }}
								<br />
								<span class="payment-type"> Payment type: </span>
								<span class="payment-type-value">
									{{ reportPaymentType }}
								</span>
							</div>
							<div>
								<div class="margin-bottom-30 margin-top-10">
									<vue-good-table
										id="dispatches"
										:columns="columns"
										:rows="listArr"
										styleClass="vgt-table striped bordered"
										theme="black-rhino"
										max-height="1100px"
										:fixed-header="true"
										@on-row-dblclick="onRowDoubleClick"
										@on-cell-click="onCellClick"
										:pagination-options="{
											enabled: true,
											mode: 'records',
											perPage: 25,
											position: 'bottom',
											perPageDropdown: [5, 10, 15, 20, 25],
											dropdownAllowAll: false,
											setCurrentPage: 1,
											jumpFirstOrLast: true,
											firstLabel: 'First',
											lastLabel: 'Last',
											nextLabel: 'next',
											prevLabel: 'prev',
											rowsPerPageLabel: 'Rows per page',
											ofLabel: 'of',
											pageLabel: 'page', // for 'pages' mode
											allLabel: 'All',
										}"
									>
										<template slot="table-row" slot-scope="props">
											<span v-if="props.column.field === 'select'">
												<b-button class="is-small is-success">Select</b-button>
											</span>
											<span v-if="props.column.field === 'preview'">
												<b-button class="is-small is-info">Preview</b-button>
											</span>
											<span v-else>
												{{ props.formattedRow[props.column.field] }}
											</span>
										</template>
									</vue-good-table>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</layout-wide>
</template>

<script>
import SubNavBar from '@/_srcv2/components/_shared/sub-navbar/SubNavbar.vue'
import dateformat from 'dateformat'
import Store from '@/store'
import {
	computed,
	onMounted,
	reactive,
	ref,
	watchEffect,
} from '@vue/composition-api'
import { useQuery, useResult } from '@vue/apollo-composable'
import GetDailyPaymentCollectingValues from '@/_srcv2/graphql/accounting/queries/collect-payment/getDailyPaymentCollectingValues.query.gql'
import useReportNumber from '@/_srcv2/views/_shared/_composables/format-report-numbers/useReportNumber'
// import SearchInvoiceByIdToViewQuery from '@/_srcv2/graphql/invoice/queries/searchInvoiceByIdToView.query.gql'
// import Router from '@/router'
// todo preview invoice
// import useInvoiceReportData from '@/_srcv2/views/pages/invoice/report/scripts/invoiceReport.js'
import GetInvoiceData from './GetInvoiceData.vue'
import ProcessInvoice from './ProcessInvoice.vue'
export default {
	name: 'GetDataCollectPayment',
	components: {
		SubNavBar,
		GetInvoiceData,
		ProcessInvoice,
	},
	setup() {
		const firstDayOfWeek = 1
		const showWeekNumber = ref(false)
		const today = ref(new Date())
		const locale = ref('sv-SE')
		const setQueryVariables = (paymentType) => {
			return new Promise((resolve, reject) => {
				if (paymentType) {
					// queryVariables.payment_type = ''
					isProcessing.value = true
					queryVariables.payment_type = paymentType
					resolve(true)
				} else {
					const error = new Error('Payment Type is not found')
					reject(error)
				}
			}).catch((err) => alert('Error: ', err))
		}
		onMounted(() => {
			// getAll()
		})
		const reportPaymentType = computed(() => {
			switch (queryVariables.payment_type) {
				case '%%%':
					return 'All Transactions'
				case '%collect bank%':
					return 'Bank Transactions'
				case '%collect cash%':
					return 'Cash Transactions'
				case '%set off%':
					return 'Set Off Transactions'
				default:
					return 'All Transactions'
			}
		})
		// ** -----------------------------------------------------------------------------------------------
		const isProcessing = ref(true)
		// ** ----------------------------------------------------------------------------------------------
		const showConfirmation = () => {
			if (
				confirm(
					`Are you sure to get data for ${reportPaymentType.value}
	To cancel edit press "ok"
	To return back press "cancel"`,
				)
			) {
				refetchDailyPaymentCollectingValues()
				isProcessing.value = false
			} else {
				return
			}
		}
		const getTableData = (searchVariable) => {
			isProcessing.value = true
			setTimeout(() => {
				setQueryVariables(searchVariable).then(() => {
					showConfirmation()
				})
			}, 500)
		}
		const getAll = () => {
			getTableData('%%%')
		}
		const getBank = () => {
			getTableData('%collect bank%')
		}
		const getCash = () => {
			getTableData('%collect cash%')
		}
		const getSetoff = () => {
			getTableData('%set off%')
		}
		const queryVariables = reactive({
			payment_date: new Date(),
			our_company: Store.getters.getUserCurrentCompany,
			payment_type: '%%%',
		})
		const selectedDate = ref(new Date())
		selectedDate.value = new Date()
		watchEffect(() => {
			queryVariables.payment_date = dateformat(selectedDate.value, 'yyyy-mm-dd')
		})
		// ** -------------------------------------------------------------------------------------------
		const listArr = computed(() => tableValues.value)
		const columns = [
			{
				field: 'preview',
				label: 'Preview',
				width: '120',
				numeric: true,
			},
			{
				field: 'paymentId',
				label: 'P. ID',
				width: '50',
				centered: true,
			},
			{
				field: 'paymentType',
				label: 'Type',
				width: '30',
				centered: true,
			},
			{
				field: 'invoiceNumber',
				label: 'Invoice Number',
				width: '65',
				centered: true,
			},
			{
				field: 'customer',
				label: 'Customer',
				width: '100',
				centered: true,
			},
			{
				field: 'paymentAmount',
				label: 'Payment A.',
				width: '120',
				numeric: true,
			},
			{
				field: 'select',
				label: 'Select',
				width: '120',
				numeric: true,
			},
		]
		const { floatedCurrencyAddedNumber } = useReportNumber()
		//--------------------------------------------------------------------------------------------
		const getPaymentAbbreviation = (item) => {
			switch (item) {
				case 'collect bank':
					return 'BT'
				case 'collect bank in advance':
					return 'BT'
				case 'collect bank reminder fee':
					return 'BT'
				case 'collect cash':
					return 'CT'
				case 'collect cash in advance':
					return 'CT'
				case 'collect cash reminder fee':
					return 'CT'
				case 'get payback via collect bank':
					return 'BT'
				case 'get payback collect cash':
					return 'BT'
				case 'pay bank':
					return 'BTU'
				case 'pay cash':
					return 'CTU'
				case 'pay bank in advance':
					return 'BTU'
				case 'pay cash in advance':
					return 'CTU'
				case 'set off':
					return 'SO'
				case 'set off credit invoice':
					return 'SO'
				case 'set off advanced payment':
					return 'SO'
				case 'setoff':
					return 'SO'
				default:
					return '??'
			}
		}
		const tableValues = ref([])
		const totalPaymentAmount = ref(0)
		const isLengthOfResultZero = ref(true)
		const getTableValues = () => {
			if (!dailyPaymentCollectingValues.value) {
				isLengthOfResultZero.value = true
				isProcessing.value = false
				alert('Nothing to show')
				return
			}
			if (dailyPaymentCollectingValues.value.length === 0) {
				isLengthOfResultZero.value = true
				isProcessing.value = false
				alert('Nothing to show')
			} else {
				isProcessing.value = false
				isLengthOfResultZero.value = false
				tableValues.value = dailyPaymentCollectingValues.value.map((item) => {
					return {
						paymentId: item.payment_id,
						invoiceNumber: item.invoice_number,
						customerId: item.pyments_customers_rel.customer_id,
						customerTitle: item.pyments_customers_rel.customer_title,
						customer:
							item.pyments_customers_rel.customer_id +
							' - ' +
							item.pyments_customers_rel.customer_title,
						paymentAmount: floatedCurrencyAddedNumber(item.payment_credit),
						paymentType: getPaymentAbbreviation(item.payment_type),
					}
				})
				totalPaymentAmount.value = floatedCurrencyAddedNumber(
					dailyPaymentCollectingValuesTotals.value.payment_credit,
				)
			}
		}
		const options = reactive({
			fetchPolicy: 'no-cache',
		})
		const {
			result: resultDailyPaymentCollectingValues,
			refetch: refetchDailyPaymentCollectingValues,
			loading,
			onResult,
		} = useQuery(GetDailyPaymentCollectingValues, () => queryVariables, options)
		const dailyPaymentCollectingValues = useResult(
			resultDailyPaymentCollectingValues,
			null,
			(data) => data.payments,
		)
		const dailyPaymentCollectingValuesTotals = useResult(
			resultDailyPaymentCollectingValues,
			null,
			(data) => data.payments_aggregate.aggregate.sum,
		)
		// ** ------------------------------------------------------------------------------------
		onResult(() => {
			getTableValues()
		})
		// ** ------------------------------------------------------------------------------------
		const collapseTable = () => (isProcessing.value = true)
		// ** ------------------------------------------------------------------------------------
		const isGetInvoiceDataOpen = ref(false)
		const closeGetData = () => {
			isGetInvoiceDataOpen.value = false
			isProcessInvoiceOpen.value = true
		}
		const isProcessInvoiceOpen = ref(false)
		const closeProcessInvoice = () => {
			isProcessInvoiceOpen.value = false
		}
		const ourCompany = ref('')
		const invoiceNumber = ref('')
		const onCellClick = (params) => {
			if (
				params.column.field === 'invoiceNumber' ||
				params.column.field === 'preview'
			) {
				console.log('**** clicked')
				ourCompany.value = Store.getters.getUserCurrentCompany
				invoiceNumber.value = params.row.invoiceNumber
				isGetInvoiceDataOpen.value = true
			}
		}
		const onRowDoubleClick = (params) => {
			console.log('selected params', params.row.invoiceNumber)
		}
		return {
			onCellClick,
			onRowDoubleClick,
			showWeekNumber,
			locale,
			today,
			selectedDate,
			listArr,
			columns,
			totalPaymentAmount,
			getAll,
			getBank,
			getCash,
			getSetoff,
			queryVariables,
			reportPaymentType,
			loading,
			isLengthOfResultZero,
			isProcessing,
			collapseTable,
			firstDayOfWeek,
			closeGetData,
			isGetInvoiceDataOpen,
			isProcessInvoiceOpen,
			closeProcessInvoice,
			invoiceNumber,
			ourCompany,
		}
	},
}
</script>

<style scoped>
.margin-top-20 {
	margin-top: 20px;
}
.payment-type {
	color: dodgerblue;
	font-weight: bold;
}
.payment-type-value {
	color: red;
	font-weight: bold;
}
.button-default {
	margin-top: 20px;
	margin-right: 20px;
}
</style>
