/** @format */

// To share the state between components write thw following three lines
// Otherwise each component creates a new instance of the reactive state object that can not share the state
import Vue from 'vue'
import VueCompositionApi from '@vue/composition-api'
Vue.use(VueCompositionApi)

import { reactive } from '@vue/composition-api'
// *** Import functions to format currency and numbers ***
import useReportNumber from '@/_srcv2/views/_shared/_composables/format-report-numbers/useReportNumber'
// *** Get the data from store ***
import Store from '@/store'

const getInvoiceReportData = () => {
	// Get report data from store
	const documentData = Store.getters.getPdfReportData
	console.log('document data from Invoice doc definition js file', documentData)
	const documentOurCompany = Store.getters.getPdfReportData.company
	const documentCustomer = Store.getters.getPdfReportData.customer
	const documentCustomerAddress =
		Store.getters.getPdfReportData.invoice_customer_address
	const documentLines =
		Store.getters.getPdfReportData.document_transaction_goods_transactions_rel
	// ----------------------------------------------------------------------------
	// Get sale-invoice language and exchange unit
	const documentLanguage = documentData.invoice_language
	const exc = documentData.invoice_exchange_unit
	// ----------------------------------------------------------------------------
	// Get related orders and dispatches
	console.log('*********', Store.getters.getPdfReportData.related_dispatches)
	const relatedDispatchesArray =
		Store.getters.getPdfReportData.related_dispatches.map((item) => {
			return {
				dispatchNumber: item.dispatch_number,
				dispatchDate:
					item.goods_transactions_dispatch_haeaders_rel.dispatch_date,
				dispatchAddressNickname:
					item.goods_transactions_dispatch_haeaders_rel
						.dispatch_customer_address.address_nickname,
			}
		})
	console.log('.............', relatedDispatchesArray)
	const relatedDispatchesNumbersArray =
		Store.getters.getPdfReportData.related_dispatches.map(
			(item) => item.dispatch_number,
		)
	console.log('.............***', relatedDispatchesNumbersArray)
	const relatedOrdersRaw = Store.getters.getPdfReportData.related_orders.map(
		(item) => item.order_number,
	)
	const relatedDispatches = String(relatedDispatchesNumbersArray).replaceAll(
		',',
		', ',
	)
	const relatedOrders = String(relatedOrdersRaw).replaceAll(',', ', ')
	// ** ----------------------------------------------------------------------------
	let invoiceRule = ''
	let sendInvoiceBy = ''
	const routeId = documentCustomerAddress._cities.route_id_by_city
	const invoiceNote = () => {
		if (documentCustomer.customer_invoice_rule === 'monthly') {
			invoiceRule = 'M'
		} else {
			invoiceRule = 'D'
		}
		if (documentCustomer.send_invoice_by === 'by e-mail') {
			sendInvoiceBy = 'Email'
		} else if (documentCustomer.send_invoice_by === 'by post') {
			sendInvoiceBy = 'Post'
		} else {
			sendInvoiceBy = 'Leverans'
		}
		return `Faktura => ${invoiceRule} - ${sendInvoiceBy} - ${routeId}`
	}
	// ** -----------------------------------------------------------------------
	// Get sale-invoice lines total total
	const { floatedCurrencyAddedNumber } = useReportNumber()
	// lines total total
	const invoicesLinesTotalArr = documentLines.map((item) =>
		documentData.invoice_type === 'Inland'
			? item.line_price_total_credit
			: item.line_price_total_credit_exchange,
	)
	console.log('invoicesLinesTotalArr', invoicesLinesTotalArr)
	// ----------------------------------------------------------
	const getInvoiceSubTotal = invoicesLinesTotalArr.reduce(function (
		result,
		item,
	) {
		return result + item
	},
	0)
	console.log('sales sale-invoice subtotal', getInvoiceSubTotal)
	// ----------------------------------------------------------------------------
	// Get lines vat total
	// ************** sale-invoice type göre exc
	const invoicesLinesVatTotalArr = documentLines.map((item) =>
		documentData.invoice_type === 'Inland'
			? item.vat_credit
			: item.vat_credit_exchange,
	)
	console.log('invoicesLinesTotalArr', invoicesLinesTotalArr)
	// ----------------------------------------------------------
	const getSumVat = invoicesLinesVatTotalArr.reduce(function (result, item) {
		return result + item
	}, 0)
	console.log('sales sale-invoice getSumVat', getSumVat)
	// ----------------------------------------------------------------------------
	// TO PAY
	let toPay = (getSumVat + getInvoiceSubTotal) / 100
	console.log('TO PAY', toPay)
	let correctedTotal
	let roundingAmount
	let rounding = toPay - parseInt(toPay)
	if (rounding < 0.5) {
		correctedTotal = parseInt(toPay)
		roundingAmount = -1 * rounding
	} else {
		correctedTotal = parseInt(toPay) + 1
		roundingAmount = 1 - rounding
	}
	// ----------------------------------------------------------------------------
	// Get sale-invoice vat array
	let vat12 = 0
	let vat25 = 0
	const invoicesLinesVatArr = documentLines.map((item) => [
		item.vat_percent,
		item.vat_credit,
	])
	console.log('invoicesLinesVatArr', invoicesLinesVatArr)
	invoicesLinesVatArr.forEach((item) => {
		if (item[0] === 25) {
			vat25 = vat25 + item[1]
		} else if (item[0] === 12) {
			vat12 = vat12 + item[1]
		}
	})
	console.log('vat12', vat12)
	console.log('vat25', vat25)
	let invoiceVatArr = []
	if (vat25 !== 0) {
		invoiceVatArr.push({ vat: '25%', vatAmount: vat25 })
	}
	if (vat12 !== 0) {
		invoiceVatArr.push({ vat: '12%', vatAmount: vat12 })
	}
	if (vat25 === 0 && vat12 === 0) {
		invoiceVatArr.push({ vat: '0%', vatAmount: 0 })
	}
	// ----------------------------------------------------------------------------
	// Get invoice_price
	const getInvoicePrice = (item) => {
		return documentData.invoice_type === 'Inland'
			? item.invoice_price
			: item.invoice_price_exchange
	}
	// ----------------------------------------------------------------------------
	// Get line_price_total_credit
	const getLinePriceTotalCredit = (item) => {
		return documentData.invoice_type === 'Inland'
			? item.line_price_total_credit
			: item.line_price_total_credit_exchange
	}
	// Get creditTag
	const getCreditTag = () => {
		switch (documentLanguage) {
			case 'en':
				return toPay < 0 ? 'Credit' : ''
			default:
				return toPay < 0 ? 'Kredit' : ''
		}
	}
	// Get Bank account
	const bankAccountNumber = documentOurCompany.company_bank_giro
	const ibanNumber = `${documentOurCompany.bic_code}  -  ${documentOurCompany.iban_code}`
	// const getBankAccountNumber = () => {
	// 	if (Store.getters.getPdfReportData.invoice_type === 'Inland') {
	// 		bankAccountNumber = documentOurCompany.company_bank_giro
	// 	} else {
	// 		bankAccountNumber = `BIC: ${documentOurCompany.bic_code}  -  IBAN: ${documentOurCompany.iban_code}`
	// 	}
	// }
	// getBankAccountNumber()
	let documentTypeTag
	let dateIssuedTag
	let dueDateTag
	let customerNumberTag
	let fromTag
	let ourRefTag
	let addressTag
	let toTag
	let yourRefTag
	let orgNumberTag
	let remarksTag
	let contactTag
	let telTag
	let emailTag
	let productUnitTag
	let qtyTag
	let priceTag
	let taxTag
	let totalTag
	let subTotalTag
	let toPayTag
	let messageTag
	let pageTag
	let ofTag
	let roundingTag
	let relatedDispatchTag
	let relatedOrdersTag
	let notesLine4Tag
	// let signatureName
	// let jobTitle
	// let paymentInfoTag
	let descriptionToCustomer
	// let attentionTag
	let creditTag = getCreditTag()
	switch (documentLanguage) {
		case 'en':
			documentTypeTag = `${creditTag} Invoice`
			dateIssuedTag = 'Issued Date'
			dueDateTag = 'Due Date'
			customerNumberTag = 'Customer Number'
			fromTag = 'From'
			ourRefTag = 'Our ref: '
			addressTag = 'Address'
			toTag = 'To'
			yourRefTag = 'Your ref: '
			orgNumberTag = 'Tax. Number: '
			remarksTag = 'Remarks'
			contactTag = 'Contact '
			telTag = 'Tel: '
			emailTag = ' Email: '
			productUnitTag = 'item - amount'
			qtyTag = 'Qty'
			priceTag = 'Price'
			taxTag = 'VAT'
			totalTag = 'Total'
			subTotalTag = 'Subtotal'
			toPayTag = 'TO PAY'
			messageTag = 'Message'
			pageTag = 'page'
			ofTag = ' of'
			roundingTag = 'rounding of the price'
			relatedDispatchTag = 'Related dispatch-lists: '
			relatedOrdersTag = 'Related Orders: '
			notesLine4Tag = invoiceNote()
			// signatureName = 'Your name'
			// jobTitle = 'Your job title'
			// paymentInfoTag = 'Payment information'
			descriptionToCustomer = `Write the invoice number (${documentData.invoice_number}) as message when you pay`
			// attentionTag = 'Attention!: '
			break
		default:
			documentTypeTag = `${creditTag} Faktura`
			dateIssuedTag = 'Datum'
			dueDateTag = 'Oss tillhanda'
			customerNumberTag = 'Kundnummer'
			fromTag = 'Från'
			ourRefTag = 'Vår Ref: '
			addressTag = 'Adress'
			toTag = 'Till'
			yourRefTag = 'Err Ref: '
			orgNumberTag = 'Org Nummer: '
			remarksTag = 'Anteckningarna'
			contactTag = 'Kontakt '
			telTag = 'Tel: '
			emailTag = ' Epost: '
			productUnitTag = 'artikel -enhet'
			qtyTag = 'antal'
			priceTag = 'pris'
			taxTag = 'moms'
			totalTag = 'total'
			subTotalTag = 'subtotal'
			toPayTag = 'Att betala'
			messageTag = 'Meddelande'
			pageTag = 'sida'
			ofTag = ' av'
			roundingTag = 'öresutjämning'
			relatedDispatchTag = 'Relaterade följesedlar: '
			relatedOrdersTag = 'Relaterade order: '
			notesLine4Tag = invoiceNote()
			// signatureName = 'Ditt namn'
			// jobTitle = 'Din arbetstitel'
			// paymentInfoTag = 'Betalningsuppgifter'
			descriptionToCustomer = `Påminnelseavgift:60,00 kr. - Dröjsmålsränta:8,00 % + referensränta - Inkassoarvode:180,00 kr.`
		// attentionTag = 'Obs!: '
	}
	const data = {
		// headerLeft: 'myLeftHeader',
		// headerCenter: 'myCenterHeader',
		// headerRight: 'myRightHeader',
		footerLeft: 'Godkänd för F-skatt',
		footerCenter: 'myCenterFooter',
		// footerRight: 'myRightFooter',
		logo: documentOurCompany.company_logo_64base,
		watermark: documentOurCompany.company_watermark_64base,
		documentType: documentTypeTag,
		documentNumber: documentData.invoice_number,
		dateIssued: documentData.invoice_date,
		dateIssuedLabel: dateIssuedTag,
		dueDate: documentData.invoice_due_date,
		dueDateLabel: dueDateTag,
		bankGiroLabel: 'Bankgiro',
		ibanLabel: 'BIC - IBAN',
		messageLabel: messageTag,
		customerNumber: documentCustomer.customer_id,
		customerNumberLabel: customerNumberTag,
		from: fromTag,
		billingFromLabel: fromTag,
		ourRef: ourRefTag,
		reference: documentOurCompany.company_reference,
		ourCompany: documentOurCompany.company_name,
		addressLabel: addressTag,
		addressLine1:
			documentOurCompany.company_address_line_1 +
			' ' +
			documentOurCompany.company_address_line_2,
		addressLine2:
			documentOurCompany.company_post_code +
			' ' +
			documentOurCompany.company_city,
		addressLine3:
			documentOurCompany.company_state +
			' ' +
			documentOurCompany.company_country,
		addressLine4: '',
		orgNum: documentOurCompany.company_org_num,
		to: toTag,
		billingToLabel: toTag,
		yourRef: yourRefTag,
		toReference: documentCustomer.customer_reference,
		clientCompany: documentCustomer.customer_title,
		clientNickName: documentCustomer.customer_nickname,
		toAddressLine1:
			documentCustomerAddress.line_1 + ' ' + documentCustomerAddress.line_2,
		toAddressLine2:
			documentCustomerAddress.post_code + ' ' + documentCustomerAddress.city,
		toAddressLine3:
			documentCustomerAddress.state + ' ' + documentCustomerAddress.country,
		toAddressLine4: '',
		toOrgNum: documentCustomer.customer_org_num,
		documentLines: documentLines,
		orgNumberLabel: orgNumberTag,
		getInvoiceSubTotal: floatedCurrencyAddedNumber(getInvoiceSubTotal, exc),
		invoicesLinesVatArr: invoiceVatArr,
		invoiceTotal: floatedCurrencyAddedNumber(correctedTotal * 100, exc),
		// correctedTotal.toFixed(2) + ' ' + exc,
		invoiceRounding: floatedCurrencyAddedNumber(roundingAmount * 100, exc),
		// roundingAmount.toFixed(2) + ' ' + exc,
		notesLabel: remarksTag,
		notesLine1:
			contactTag +
			telTag +
			documentOurCompany.company_tel +
			emailTag +
			documentOurCompany.company_email,
		notesLine2: relatedDispatches,
		notesLine3: relatedOrders,
		relatedDispatchLabel: relatedDispatchTag,
		relatedOrderLabel: relatedOrdersTag,
		notesLine4: notesLine4Tag,
		toPayAmountInCurrency: floatedCurrencyAddedNumber(
			correctedTotal * 100,
			exc,
		),
		productUnitLabel: productUnitTag,
		qty: qtyTag,
		price: priceTag,
		tax: taxTag,
		total: totalTag,
		subTotalLabel: subTotalTag,
		totalLabel: toPayTag,
		page: pageTag,
		of: ofTag,
		exchange: exc,
		rounding: roundingTag,
		// paymentTitle: paymentInfoTag,
		paymentDescription: descriptionToCustomer,
		// attentionLabel: attentionTag,
	}
	// todo create qr code -----------------------------
	// ** get payment amount
	const getPaymentAmount = (rawAmount) => {
		console.log('>>>> rawAmount', rawAmount)
		return parseInt(rawAmount.slice(0, -7).replaceAll(',', ''))
	}
	const qrContent = reactive({
		uqr: 1,
		tp: 1,
		nme: documentData.company.company_name,
		cid: documentData.customer.customer_id,
		iref: documentData.invoice_number, // ** Fakturanummer
		idt: documentData.invoice_date.replaceAll('-', ''), // ** Fakturadatum
		ddt: documentData.invoice_due_date.replaceAll('-', ''), // ** Förfallodatutm
		due: getPaymentAmount(data.invoiceTotal), // ** Att betala
		pt: 'BG', // ** BG or PG
		acc: documentData.company.company_bank_giro, // ** Kontonummer
	})
	// qrString.value = JSON.stringify(qrContent)
	// todo end -------------------------------
	let rowsVatItems = []
	for (let item of data.invoicesLinesVatArr) {
		rowsVatItems.push([
			{
				text: '',
			},
			{
				text: 'moms ' + item.vat,
				style: 'itemVat',
				color: '#555555',
				fillColor: '#dedede',
			},
			{
				text: floatedCurrencyAddedNumber(item.vatAmount, exc),
				style: 'itemVatAmount',
				color: '#555555',
				fillColor: '#dedede',
			},
		])
	}
	const docDefinition = reactive({
		pageSize: 'A4',
		pageOrientation: 'portrait',
		pageMargins: [50, 20, 40, 70],
		// header: {
		//   columns: [
		//     { text: data.headerLeft, style: "documentHeaderLeft" },
		//     { text: data.headerCenter, style: "documentHeaderCenter" },
		//     { text: data.headerRight, style: "documentHeaderRight" }
		//   ]
		// },
		// footer: {
		//   columns: [
		//     { text: data.footerLeft, style: "documentFooterLeft" },
		//     { text: data.footerCenter, style: "documentFooterLineCenter" },
		//     { text: data.footerCenter, style: "documentFooterRight" }
		//   ]
		// },
		footer: function (currentPage, pageCount) {
			return {
				columns: [
					// {
					//   table: {
					//     widths: ['*'],
					//     body: [[' '], [' ']]
					//   },
					//   style: 'documentFooterLineLeft',
					//   layout: 'lightHorizontalLines'
					// },
					{ text: data.footerLeft, style: 'documentFooterLeft' },
					// {
					//   table: {
					//     widths: ['*'],
					//     body: [[' '], [' ']]
					//   },
					//   style: 'documentFooterLineCenter',
					//   layout: 'lightHorizontalLines'
					// },
					{
						text: data.page + currentPage.toString() + data.of + pageCount,
						style: 'documentFooterRight',
					},
				],
			}
		},
		// background: function() {
		//   return {
		//     image: data.watermark,
		//     opacity: 0.3,
		//     alignment: 'center',
		//     height: 264,
		//     margin: [0, (842 - 264) / 2, 0, 0]
		//   }
		// },
		background: [
			{
				image: data.watermark,
				opacity: 0.3,
				alignment: 'center',
				height: 264,
				margin: [0, (842 - 264) / 2, 0, 0],
			},
			{
				canvas: [
					{
						type: 'line',
						x1: 50,
						y1: 245,
						x2: 570,
						y2: 245,
						lineWidth: 1,
					},
				],
			},
		],
		content: [],
		styles: {
			// Document Header
			// documentHeaderLeft: {
			// 	fontSize: 10,
			// 	margin: [5, 5, 5, 40],
			// 	alignment: 'left',
			// },
			// documentHeaderCenter: {
			// 	fontSize: 10,
			// 	margin: [5, 5, 5, 40],
			// 	alignment: 'center',
			// },
			// documentHeaderRight: {
			// 	fontSize: 10,
			// 	margin: [5, 5, 5, 40],
			// 	alignment: 'right',
			// },
			// Document Footer
			documentFooterLeft: {
				fontSize: 12,
				bold: true,
				margin: [50, 30, 5, 40],
				alignment: 'left',
			},
			documentFooterLineLeft: {
				fontSize: 10,
				margin: [50, 0, 5, 0],
				alignment: 'left',
			},
			documentFooterLineCenter: {
				fontSize: 10,
				margin: [0, 0, 5, 0],
				alignment: 'center',
			},
			documentFooterLineRight: {
				fontSize: 10,
				margin: [0, 0, 5, 50],
				alignment: 'right',
			},
			documentFooterRight: {
				fontSize: 10,
				margin: [5, 30, 40, 40],
				alignment: 'right',
			},
			// Document Title
			documentTitle: {
				fontSize: 18,
				bold: true,
				alignment: 'right',
				margin: [0, 0, 0, 15],
			},
			// Document Details
			documentSubTitle: {
				fontSize: 10,
				alignment: 'right',
			},
			documentSubValue: {
				fontSize: 10,
				alignment: 'right',
				bold: true,
			},
			// Billing Headers
			documentBillingTitle: {
				fontSize: 11,
				bold: true,
				alignment: 'left',
				margin: [0, 10, 0, 5],
			},
			// Billing Details
			documentBillingCompanyDetails: {
				alignment: 'left',
				fontSize: 11,
				bold: true,
				margin: [50, -18, 0, 0],
			},
			documentBillingDetails: {
				alignment: 'left',
				fontSize: 10,
				margin: [50, 0, 0, 0],
			},
			documentBillingAddressTitle: {
				margin: [50, 2, 0, 1],
				bold: true,
				fontSize: 10,
			},
			documentBillingAddress: {
				fontSize: 10,
				margin: [50, 0, 0, 0],
			},
			organizationNumber: {
				margin: [50, 0, 0, -15],
				bold: true,
				fontSize: 10,
			},
			dispatchNumber: {
				margin: [0, 6, 0, 6],
				bold: true,
				fontSize: 11,
			},
			// to pay
			cellsToPay: {
				margin: [5, 1, 5, 2],
				bold: true,
				fontSize: 10,
			},
			cellsToPayExtraTopMargin: {
				margin: [5, 5, 5, 2],
				bold: true,
				fontSize: 10,
			},
			qrStyle: {
				margin: [0, -95, 0, 5],
				bold: false,
				fontSize: 9,
				alignment: 'right',
			},
			tableToPay: {
				margin: [0, 2, 0, 0],
			},
			// Items Header
			itemsHeader: {
				margin: [0, 5, 0, 5],
				bold: true,
				fontSize: 10,
			},
			// Item Title
			itemTitle: {
				bold: true,
				fontSize: 10,
			},
			itemSubTitle: {
				italics: true,
				fontSize: 10,
			},
			itemNumberTotal: {
				margin: [0, 5, 10, 5],
				alignment: 'right',
				fontSize: 10,
			},
			itemNumberPrice: {
				margin: [2, 5, 2, 5],
				alignment: 'right',
				fontSize: 10,
			},
			itemNumberC: {
				margin: [0, 5, 0, 5],
				alignment: 'center',
				fontSize: 10,
			},
			itemTotal: {
				margin: [0, 5, 0, 5],
				bold: true,
				alignment: 'center',
				fontSize: 10,
			},
			// Items Footer (Subtotal, Total, Tax, etc)
			itemsFooterSubTitle: {
				margin: [0, 5, 0, 0],
				bold: true,
				alignment: 'right',
				fontSize: 10,
			},
			itemsFooterSubValue: {
				margin: [0, 5, 13, 0],
				bold: true,
				alignment: 'right', // center
				fontSize: 10,
			},
			itemsFooterTotalTitle: {
				margin: [0, 3, 0, 5],
				bold: true,
				alignment: 'right',
				fontSize: 10,
			},
			itemsFooterTotalValue: {
				margin: [0, 3, 12, 5], // *******************
				bold: true,
				alignment: 'right',
				fontSize: 10,
			},
			signaturePlaceholder: {
				margin: [0, 70, 0, 0],
				fontSize: 10,
			},
			signatureName: {
				bold: true,
				alignment: 'center',
				fontSize: 10,
			},
			signatureJobTitle: {
				italics: true,
				fontSize: 10,
				alignment: 'center',
			},
			notesTitle: {
				fontSize: 10,
				bold: true,
				margin: [0, 10, 0, 3],
			},
			notesTitleTop: {
				fontSize: 12,
				bold: true,
				margin: [0, 0, 0, 3],
			},
			notesText: {
				fontSize: 10,
			},
			notesTextBold: {
				fontSize: 12,
				bold: true,
			},
			descriptionToCustomer: {
				fontSize: 10,
				bold: false,
				margin: [0, -12, 0, 7],
			},
			center: {
				alignment: 'center',
			},
			itemVat: {
				margin: [2, 5, 2, 5],
				alignment: 'right',
				fontSize: 10,
			},
			itemVatAmount: {
				margin: [0, 5, 14, 5],
				alignment: 'right',
				fontSize: 10,
			},
			paymentTableStyle: {
				margin: [0, 10, 0, 0],
			},
		},
		tableHeader: {
			bold: true,
			alignment: 'center',
			fontSize: 10,
		},
		defaultStyle: {
			columnGap: 20,
		},
	})
	// ********************** PUSH CONTENT BEGIN********************************************************
	const header = {
		columns: [
			{
				image: data.logo,
				width: 70,
			},
			[
				{
					text: data.documentType,
					style: 'documentTitle',
					width: '*',
				},
				{
					stack: [
						{
							columns: [
								{
									text: data.documentType + ' #',
									style: 'documentSubTitle',
									width: '*',
								},
								{
									text: data.documentNumber,
									style: 'documentSubValue',
									width: 100,
								},
							],
						},
						{
							columns: [
								{
									text: data.dateIssuedLabel,
									style: 'documentSubTitle',
									width: '*',
								},
								{
									text: data.dateIssued,
									style: 'documentSubValue',
									width: 100,
								},
							],
						},
						{
							columns: [
								{
									text: data.customerNumberLabel,
									style: 'documentSubTitle',
									width: '*',
								},
								{
									text: data.customerNumber,
									style: 'documentSubValue',
									width: 100,
								},
							],
						},
					],
				},
			],
		],
	}
	const billingHeaders = {
		columns: [
			{
				text: data.billingFromLabel,
				style: 'documentBillingTitle',
			},
			{
				text: data.billingToLabel,
				style: 'documentBillingTitle',
			},
			// {
			//   text: data.billingFromLabel,
			//   style: 'documentBillingTitle',
			// },
		],
	}
	const billingDetails = {
		columns: [
			{
				text: data.ourCompany,
				style: 'documentBillingCompanyDetails',
			},
			{
				text: data.clientCompany,
				style: 'documentBillingCompanyDetails',
			},
			// {
			//   text: data.ourCompany,
			//   style: 'documentBillingCompanyDetails',
			// },
		],
	}
	const references = {
		columns: [
			{
				text: data.ourRef + data.reference,
				style: 'documentBillingDetails',
			},
			{
				text: data.yourRef + data.toReference,
				style: 'documentBillingDetails',
			},
			// {
			//   text: data.ourRef + data.reference,
			//   style: 'documentBillingDetails',
			// },
		],
	}
	const nicknames = {
		columns: [
			{
				text: '',
				style: 'documentBillingDetails',
			},
			{
				text: data.clientNickName,
				style: 'documentBillingDetails',
			},
			// {
			//   text: '',
			//   style: 'documentBillingDetails',
			// },
		],
	}
	const addressTitle = {
		columns: [
			{
				text: data.addressLabel,
				style: 'documentBillingAddressTitle',
			},
			{
				text: data.addressLabel,
				style: 'documentBillingAddressTitle',
			},
		],
	}
	const billingAddress = {
		columns: [
			{
				text:
					data.addressLine1 +
					' \n ' +
					data.addressLine2 +
					' \n ' +
					data.addressLine3 +
					' \n ',
				style: 'documentBillingAddress',
			},
			{
				text:
					data.toAddressLine1 +
					' \n ' +
					data.toAddressLine2 +
					' \n ' +
					data.toAddressLine3 +
					' \n ',
				style: 'documentBillingAddress',
			},
			// {
			//   text:
			//     data.addressLine1 +
			//     ' \n ' +
			//     data.addressLine2 +
			//     ' \n ' +
			//     data.addressLine3 +
			//     ' \n ',
			//   style: 'documentBillingAddress',
			// },
		],
	}
	const orgNumber = {
		columns: [
			{
				text: data.orgNumberLabel + data.orgNum,
				style: 'organizationNumber',
			},
			{
				text: data.orgNumberLabel + data.toOrgNum,
				style: 'organizationNumber',
			},
			// {
			//   text: data.orgNumberLabel + data.orgNum,
			//   style: 'organizationNumber',
			// },
		],
	}
	const horizontalLine = {
		table: {
			widths: ['*'],
			body: [[' '], [' ']],
		},
		layout: 'lightHorizontalLines',
	}
	// **********************************************************************************
	const toPayInfo = {
		stack: [
			{
				style: 'tableToPay',
				table: {
					widths: ['auto', 'auto', 150],
					body: [
						[
							{
								text: toPayTag,
								color: '#555555',
								fillColor: '#dedede',
								alignment: 'left',
								style: 'cellsToPayExtraTopMargin',
							},
							{
								text: data.toPayAmountInCurrency,
								color: '#555555',
								fillColor: '#dedede',
								alignment: 'right',
								style: 'cellsToPayExtraTopMargin',
							},
							{
								text: '',
								alignment: 'left',
								style: 'cellsToPayExtraTopMargin',
							},
						],
						[
							{
								text: data.dueDateLabel,
								color: '#555555',
								fillColor: '#dedede',
								alignment: 'left',
								style: 'cellsToPay',
							},
							{
								text: data.dueDate,
								color: '#555555',
								fillColor: '#dedede',
								alignment: 'right',
								style: 'cellsToPay',
							},
							{
								text: '',
								alignment: 'left',
								style: 'cellsToPay',
							},
						],
						[
							{
								text: data.bankGiroLabel,
								color: '#555555',
								fillColor: '#dedede',
								alignment: 'left',
								style: 'cellsToPay',
							},
							{
								text: bankAccountNumber,
								color: '#555555',
								fillColor: '#dedede',
								alignment: 'right',
								style: 'cellsToPay',
							},
							{
								text: '',
								alignment: 'left',
								style: 'cellsToPay',
							},
						],
						[
							{
								text: data.messageLabel,
								color: '#555555',
								fillColor: '#dedede',
								alignment: 'left',
								style: 'cellsToPay',
							},
							{
								text: data.documentNumber,
								color: '#555555',
								fillColor: '#dedede',
								alignment: 'right',
								style: 'cellsToPay',
							},
							{
								text: '',
								alignment: 'left',
								style: 'cellsToPay',
							},
						],
						[
							{
								text: data.ibanLabel,
								color: '#555555',
								fillColor: '#e6a5a5',
								alignment: 'left',
								style: 'cellsToPay',
							},
							{
								text: ibanNumber,
								color: '#555555',
								fillColor: '#e6a5a5',
								alignment: 'right',
								style: 'cellsToPay',
							},
							{
								text: '',
								alignment: 'left',
								style: 'cellsToPay',
							},
						],
					],
				},
				layout: 'noBorders',
				defaultStyle: {
					alignment: 'left',
				},
			},
			{
				qr: JSON.stringify(qrContent),
				fit: '100',
				style: 'qrStyle',
			},
		],
	}
	// **********************************************************************************
	// const paymentTitle = {
	// 	text: data.paymentTitle,
	// 	style: 'notesTitleTop',
	// }
	const notes = {
		text: `${data.paymentDescription}`,
		style: 'descriptionToCustomer',
	}
	// ** Create rowsItems array (tables depends on dispatches)
	let rowsItems = []
	// Push table headers for each dispatch
	const pushRowsItemsHeaders = () => {
		rowsItems.push([
			{
				text: data.productUnitLabel,
				style: 'itemsHeader',
			},
			{
				text: data.qty,
				style: ['itemsHeader', 'center'],
			},
			{
				text: data.tax,
				style: ['itemsHeader', 'center'],
			},
			{
				text: data.price,
				style: ['itemsHeader', 'center'],
			},
			{
				text: data.total,
				style: ['itemsHeader', 'center'],
			},
		])
	}
	// ** Push dispatch lines for each dispatch
	const pushRowsItemsLines = (dispatchLines) => {
		for (let item of dispatchLines) {
			rowsItems.push([
				{
					text: item.line_info,
					style: 'itemSubTitle',
				},
				{
					text: item.dispatch_amount,
					style: 'itemNumberC',
				},
				{
					text: item.vat_percent + ' %',
					style: 'itemNumberC',
				},
				{
					text: floatedCurrencyAddedNumber(getInvoicePrice(item), exc),
					style: 'itemNumberPrice',
				},
				{
					text: floatedCurrencyAddedNumber(getLinePriceTotalCredit(item), exc),
					style: 'itemNumberTotal',
				},
			])
		}
	}
	// ** ----------------------------------------------------------
	const pushDispatchTables = (item) => {
		docDefinition.content.push({
			stack: [
				{
					text: `${item.dispatchNumber}  -  ${item.dispatchDate}  -  ${item.dispatchAddressNickname}`,
					style: 'dispatchNumber',
				},
				{
					table: {
						// headers are automatically repeated if the table spans over multiple pages
						// you can declare how many rows should be treated as headers
						headerRows: 1,
						widths: ['*', 30, 30, 50, 80],
						style: 'tableHeader',
						body: rowsItems,
					},
				},
			],
			margin: [0, 10, 0, 0],
			unbreakable: rowsItems.length > 11 ? false : true,
		})
	}
	const createTablesDependsOnDispatchNumbers = () => {
		for (let item of relatedDispatchesArray) {
			rowsItems = []
			let dispatchLines = []
			// console.log('dispatch number', item)
			dispatchLines = data.documentLines.filter(
				(line) => line.dispatch_number === item.dispatchNumber,
			)
			pushRowsItemsHeaders()
			pushRowsItemsLines(dispatchLines)
			pushDispatchTables(item)
		}
	}
	// --------------------------------------------------------------------------------------------
	const paymentTable = {
		stack: [
			{
				table: {
					// headers are automatically repeated if the table spans over multiple pages
					// you can declare how many rows should be treated as headers
					headerRows: 0,
					widths: ['*', 86, 90],
					body: [
						// Total
						[
							{
								text: '',
							},
							{
								text: data.subTotalLabel,
								style: 'itemsFooterSubTitle',
								color: '#555555',
								fillColor: '#dedede',
							},
							{
								text: data.getInvoiceSubTotal,
								style: 'itemsFooterSubValue',
								color: '#555555',
								fillColor: '#dedede',
							},
						],
					],
				}, // table
				layout: 'noBorders',
			},
			{
				layout: 'noBorders',
				table: {
					headerRows: 0,
					widths: ['*', 86, 90],
					body: rowsVatItems,
				},
			},
			{
				table: {
					// headers are automatically repeated if the table spans over multiple pages
					// you can declare how many rows should be treated as headers
					headerRows: 0,
					widths: ['*', 86, 90],
					body: [
						// Total
						[
							{
								text: '',
							},
							{
								text: data.rounding,
								style: 'itemsFooterTotalTitle',
								color: '#555555',
								fillColor: '#dedede',
							},
							{
								text: data.invoiceRounding,
								style: 'itemsFooterTotalValue',
								color: '#555555',
								fillColor: '#dedede',
							},
						],
						[
							{
								text: '',
							},
							{
								text: data.totalLabel,
								style: 'itemsFooterTotalTitle',
								color: '#555555',
								fillColor: '#e6a5a5',
							},
							{
								text: data.invoiceTotal,
								style: 'itemsFooterTotalValue',
								color: '#555555',
								fillColor: '#e6a5a5',
							},
						],
					],
				}, // table
				layout: 'noBorders',
			},
		],
		unbreakable: true,
		style: 'paymentTableStyle',
	}
	// --------------------------------------------------------------------------------------------
	const invoiceNotesStack = {
		stack: [
			{
				text: data.notesLabel,
				style: 'notesTitle',
			},
			{
				text: `${data.notesLine1}
      ${data.relatedDispatchLabel} ${data.notesLine2}
      ${data.relatedOrderLabel} ${data.notesLine3}
			${data.notesLine4}
			`,
				style: 'notesText',
			},
		],
		unbreakable: true,
	}
	docDefinition.content.push(header)
	docDefinition.content.push(billingHeaders)
	docDefinition.content.push(billingDetails)
	docDefinition.content.push(references)
	docDefinition.content.push(nicknames)
	docDefinition.content.push(addressTitle)
	docDefinition.content.push(billingAddress)
	docDefinition.content.push(orgNumber)
	docDefinition.content.push(horizontalLine)
	// ** ----------------------------------------------------------------
	docDefinition.content.push(notes)
	docDefinition.content.push(toPayInfo)
	// docDefinition.content.push(horizontalLine)
	// docDefinition.content.push(paymentTitle)
	docDefinition.content.push(horizontalLine)
	createTablesDependsOnDispatchNumbers()
	docDefinition.content.push(paymentTable)
	docDefinition.content.push(invoiceNotesStack)
	// ********************** PUSH CONTENT END********************************************************
	return docDefinition
}

export default function useInvoiceReportData() {
	return {
		getInvoiceReportData,
	}
}
